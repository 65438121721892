import countryCodesJson from "./countryCodes.json"
import slugify from "slugify"
import { SelectOption } from "./form/types"

type mappedCountries = {
  [code: string]: {
    value: string
    label: string
  }
}

// cannot be modified
export const map = countryCodesJson as mappedCountries

const sortByAlphabet = (a: SelectOption<string>, b: SelectOption<string>) => {
  const first = slugify(a.label.toLowerCase())
  const last = slugify(b.label.toLowerCase())
  if (first < last) {
    return -1
  }
  if (first > last) {
    return 1
  }
  return 0
}

const listAlpha3Values = Object.entries(map)
  .filter(([key]) => key.length === 3)
  .map(([, value]) => value)
  .sort(sortByAlphabet)

export const list = Object.entries(map)
  .filter(([key]) => key.length === 3)
  .map(([, item]) => ({ label: item.label, value: item.label }))
  .sort((a, b) => {
    const first = slugify(a.label)
    const last = slugify(b.label)
    if (first < last) {
      return -1
    }
    if (first > last) {
      return 1
    }
    return 0
  })

export const listAlpha3 = Object.entries(map)
  .filter(([key]) => key.length === 3)
  .map(([, item]) => item)
  .sort(sortByAlphabet)

export const getCodeByLabel = (label?: string | null) => {
  const current = listAlpha3Values.find(item => item?.label === label)
  return current?.value || label || ""
}
export const getLabelByCode = (alpha3Code: string | null) => {
  const key = alpha3Code?.toUpperCase() || ""
  return map[key]?.label || alpha3Code
}

export default {
  list,
  listAlpha3,
  sortByAlphabet,
  getCodeByLabel,
  getLabelByCode,
}
