import { components } from "react-select"
import React from "react"
import styled from "styled-components"

const Input = styled(components.Input).attrs({
  placeholder: "Use ; to add several",
})`
  width: 100%;
`

export const MultiSelectInput = (props: any) => {
  const onPaste = (event: any) => {
    event.currentTarget.value = event.clipboardData.getData("text").replaceAll("\n", ";")
    props.onChange(event)
    event.preventDefault()
  }

  return <Input {...props} maxLength={255} onPaste={onPaste} />
}
