import { useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router"
import { useMe } from "hooks"
import { useLocation } from "react-use"
import styled from "styled-components"

import { Alert, Box, Button, Image, Label, Spinner, Text, useAlert } from "@prosapient/prosapient-styleguide"
import { Paragraph, SubTitle, Title } from "components/shared/Layout"
import { PageContainer } from "components/shared/Layout/PageContainer"
import { PhoneSelect } from "../../shared/PhoneSelect"
import { HEADER_MOBILE_HEIGHT, ROUTES } from "components/constants"
import { sessionStorage } from "shared/storage"

import { IAuthTokenType, useUpdatePhoneMutation } from "api/__generated__/schema"

import WelcomeImage from "accets/images/account_created.svg"
import UpdatePhoneImage from "accets/images/update_phone.svg"

const StyledBox = styled(Box).attrs({ p: 10, width: "100vw" })`
  display: flex;
  flex-wrap: wrap;

  @media only screen and (max-width: 640px) {
    max-width: 100%;
    margin: 0 auto;
    align-self: flex-start;
    box-shadow: none;
    min-height: calc(var(--vh) - ${HEADER_MOBILE_HEIGHT}px);
    padding: 1rem;
  }
`

const StyledButton = styled(Button)`
  @media only screen and (max-width: 640px) {
    width: 100%;
    margin-top: auto;
    padding-top: 1px;
    padding-bottom: 1px;
    min-height: 40px;
  }
`

const TitleBr = styled(Box)`
  display: none;

  @media only screen and (max-width: 640px) {
    display: block;
    width: 100%;
  }
`

export const AskPhone = () => {
  const { push } = useHistory()
  const { search } = useLocation()
  const { me, refetch, loading } = useMe()
  const alert = useAlert(Alert.Context)
  const [updatePhone, { loading: submitLoading }] = useUpdatePhoneMutation()

  const [phone, setPhone] = useState("")
  const [isValid, setIsValid] = useState(true)
  const [isPhoneConfirmed, setIsPhoneConfirmed] = useState(false)

  const showWelcome = useMemo(() => {
    const params = new URLSearchParams(search)

    const firstSeen = params.get("welcome")
    return firstSeen === "true"
  }, [search])

  useEffect(() => {
    const _confirmed = (me?.state?.isPhoneConfirmed && (me?.phone || "")?.length > 2) || false
    setIsPhoneConfirmed(_confirmed)

    if (me?.state?.authTokenType && me?.state?.authTokenType !== IAuthTokenType.Auth) {
      window.location.href = me?.state?.isSignedUp ? ROUTES.authLogin : ROUTES.authSignUp
    }

    if (me?.phone) setPhone(me.phone)
  }, [me, loading])

  useEffect(() => {
    // if (!showWelcome && isPhoneConfirmed) goToNextPage()
  }, [isPhoneConfirmed, showWelcome])

  const goToNextPage = () => {
    const goTo = sessionStorage.get("goTo")

    if (goTo) {
      push(goTo)
    } else if (!me?.state?.isRecentEmploymentsConfirmed) {
      push(ROUTES.mostRecentEmployment)
    } else if (!me?.state?.isCompliancePassed) {
      push(ROUTES.complianceTraining)
    } else {
      push(ROUTES.workspace)
    }
  }

  const onSubmit = async () => {
    try {
      const _phone = phone.length > 2 ? `+${phone.replaceAll("+", "")}` : ""

      await updatePhone({ variables: { phone: _phone } })
      await refetch()

      goToNextPage()
    } catch (e) {
      /*eslint-disable */
      console.error(e)
      /*eslint-enable */

      // @ts-ignore
      alert.error(e?.message || e)
    }
  }

  if (loading || me?.state?.authTokenType !== IAuthTokenType.Auth) {
    // if (loading || me?.state?.authTokenType !== IAuthTokenType.Auth || (!showWelcome && isPhoneConfirmed)) {
    return (
      <PageContainer hasBackground={false}>
        <StyledBox maxWidth={750}>
          <Spinner />
        </StyledBox>
      </PageContainer>
    )
  }

  return (
    <PageContainer hasBackground={false}>
      <StyledBox maxWidth={750}>
        <Box width="100%">
          {showWelcome && (
            <>
              <Title mb={5} textAlign="center">
                Congratulations!
                <TitleBr /> Your account has been created
              </Title>

              <Image
                src={WelcomeImage}
                alt="Invitation declined"
                width="100%"
                // style={{ maxWidth: "100%", width: 300, height: 163 }}
              />
            </>
          )}

          {!showWelcome && (
            <>
              <Title mb={11} textAlign="center">
                Hi {me?.firstName || "expert"}!
                <TitleBr /> Please, update your phone number
              </Title>

              <Image
                src={UpdatePhoneImage}
                alt="update your phone number"
                width="100%"
                // style={{ maxWidth: "100%", width: 446, height: 257 }}
              />
            </>
          )}

          <Box mt={10} />

          {!isPhoneConfirmed && (
            <Box style={{ textAlign: "left" }}>
              {showWelcome && <SubTitle mb={6}>Phone number check</SubTitle>}

              <Paragraph mt={4} mb={9}>
                We use your number to contact you regarding projects you are on or projects we think would interest you.
                It will not be disclosed to any third party
              </Paragraph>

              <Box mb={8}>
                <Label style={{ width: "340px", fontSize: "1rem" }}>
                  <Text color="beta.600">Phone number</Text>

                  <PhoneSelect
                    mandatory
                    phone={phone}
                    setPhone={setPhone}
                    isValid={isValid}
                    setIsValid={setIsValid}
                    dropdownPlacement="top"
                  />
                </Label>
              </Box>
            </Box>
          )}
        </Box>

        {isPhoneConfirmed ? (
          <>
            <Box mt={30} width="100%" />
            <StyledButton style={{ marginLeft: "auto", marginRight: "auto" }} type="submit" onClick={goToNextPage}>
              Continue
            </StyledButton>
          </>
        ) : (
          <StyledButton
            type="submit"
            disabled={phone?.trim()?.length < 10 || !isValid}
            onClick={onSubmit}
            loading={submitLoading}
          >
            Continue
          </StyledButton>
        )}
      </StyledBox>
    </PageContainer>
  )
}
