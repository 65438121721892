import { Alert, Box, Button, Popup, PopupPlacement, Text, useAlert, VStack } from "@prosapient/prosapient-styleguide"
import { MIcon } from "components/shared/MIcon/MIcon"
import {
  ActionBox,
  ContactBox,
  EditContactItem,
  Label,
  StackItem,
  StyledButton,
  StyledCreatableSelect,
  StyledInput,
  SubLabel,
} from "../styles"

import React, { useEffect, useMemo, useState } from "react"
import { useMe } from "hooks"

import { getCurrencyLabel } from "shared/currency"
import validate from "validate.js"
import {
  IExpertPayload,
  useExpertisesQuery,
  useIndustriesQuery,
  useUpdateProfileMutation,
} from "api/__generated__/schema"
import countries from "shared/countryCodes"
import { PhoneSelect } from "components/shared/PhoneSelect"
import { TimezoneSelect } from "components/@profile/@my-profile/components/TimezoneSelect"
import { ReactSelectOption } from "shared/controls"
import { useResizeDetector } from "react-resize-detector"
import { ErrorHint } from "./ErrorHint"
import { MultiSelectInput } from "./MultiSelectInput"
import { MultiSelectValueContainer } from "./MultiSelectValueContainer"
import { LocationsSelect } from "shared/LocationsSelect"

interface SelectOption<T> {
  label: string
  value: T

  [key: string]: any
}

const EditBox = ({ children, label, subLabel }: { label: string; subLabel: string; children: JSX.Element }) => (
  <StackItem>
    <label>
      <Label>{label}</Label>
      <SubLabel fontWeight={4}>{subLabel}</SubLabel>
      {children}
    </label>
  </StackItem>
)

export const UpdateInfo = ({ onClose }: { onClose: () => void }) => {
  const [isFormInvalid, setIsFormInvalid] = useState(false)
  const [showAltEmail, setShowAltEmail] = useState(false)
  // const [isAltEmailValid, setIsAltEmailValid] = useState(true)
  const [showAltPhone, setShowAltPhone] = useState(false)
  const [isAltPhoneValid, setIsAltPhoneValid] = useState(true)
  const [isPhoneValid, setIsPhoneValid] = useState(true)
  const [phone, setPhone] = useState("")
  const [altPhone, setAltPhone] = useState("")
  const [altEmail, setAltEmail] = useState("")
  const [linkedinUrl, setLinkedinUrl] = useState("")
  const [externalUrl, setExternalUrl] = useState("")
  const [timezone, setTimezone] = useState<{ value: string; label: string } | null>(null)
  const [countryAlpha3, setCountryAlpha3] = useState<string | null>(null)
  const [expertise, setExpertise] = useState<{ value: string; label: string }[]>([])
  const [industries, setIndustries] = useState<{ value: string; label: string }[]>([])

  const alert = useAlert(Alert.Context)
  const { me, refetch } = useMe()
  const [updateProfile, { loading }] = useUpdateProfileMutation()

  const { data: expertisesData, loading: expertisesLoading } = useExpertisesQuery({ fetchPolicy: "cache-and-network" })
  const { data: industriesData, loading: industriesLoading } = useIndustriesQuery({ fetchPolicy: "cache-and-network" })

  const [orientation, setOrientation] = useState(window?.screen?.orientation?.type)
  const { width, ref } = useResizeDetector({
    handleWidth: true,
    onResize: () => {},
  })

  const popupSettings = useMemo(() => {
    const placement: PopupPlacement = width && width < 410 ? "top" : "right"
    const popupWidth = width && width < 538 ? "60vw" : "360px"

    return { placement, width: popupWidth }
  }, [width, orientation])

  const expertisesOptions = useMemo(() => {
    if (!Array.isArray(expertisesData?.expertises)) return []

    return expertisesData?.expertises.map(value => ({ value, label: value }))
  }, [expertisesData, expertisesLoading])

  const industriesOptions = useMemo(() => {
    if (!Array.isArray(industriesData?.industries)) return []

    return industriesData?.industries.map(value => ({ value, label: value }))
  }, [industriesData, industriesLoading])

  const isAltEmailValid = useMemo(() => {
    if (altEmail.trim().length === 0) return true

    return !validate(
      { from: altEmail },
      {
        from: {
          email: true,
        },
      }
    )
  }, [altEmail])

  const externalUrlValid = useMemo(() => {
    if (externalUrl.trim().length === 0) return true

    return !validate({ website: externalUrl }, { website: { url: true } })
  }, [externalUrl])

  const linkedInValid = useMemo(() => {
    if (linkedinUrl.trim().length === 0) return true

    return (
      linkedinUrl.trim().startsWith("https://") && /https:\/\/(\w{2,3}\.)?linkedin\.com\/in\/(\w+)/gm.test(linkedinUrl)
    )
  }, [linkedinUrl])

  useEffect(() => {
    if (me?.expertise) {
      setExpertise(me?.expertise.map(value => ({ value, label: value })))
    }

    if (me?.industries) {
      setIndustries(me?.industries.map(value => ({ value, label: value })))
    }

    if (me?.phone) setPhone(me?.phone || "")

    if (me?.altPhone) {
      setAltPhone(me?.altPhone || "")
      setShowAltPhone(true)
    }
    if (me?.altEmail) {
      setAltEmail(me?.altEmail || "")
      setShowAltEmail(true)
    }
    if (me?.linkedinUrl) setLinkedinUrl(me?.linkedinUrl || "")
    if (me?.externalUrl) setExternalUrl(me?.externalUrl || "")

    if (me?.countryAlpha3) {
      // @ts-ignore
      setCountryAlpha3(me?.countryAlpha3?.toUpperCase() || null)
    }

    if (me?.timezone) {
      setTimezone({ label: me?.timezone, value: me?.timezone })
    }
  }, [me])

  useEffect(() => {
    const isAltPhoneInvalid = altPhone.trim().length > 0 && !isAltPhoneValid

    setIsFormInvalid(isAltPhoneInvalid || !isAltEmailValid || !isPhoneValid || !externalUrlValid || !linkedInValid)
  }, [altPhone, phone, isAltPhoneValid, isPhoneValid, externalUrlValid, linkedInValid, isAltEmailValid])

  const onCreatableSelectChange = (key: "expertise" | "industries", array: SelectOption<string>[]) => {
    const nextValues: SelectOption<string>[] = []

    array?.forEach(({ value }: { value: string }) => {
      value
        .split(";")
        .filter((str: string) => str.trim().length > 0)
        .forEach((str: string) => {
          nextValues.push({ label: str.trim(), value: str.trim() })
        })
    })

    switch (key) {
      case "expertise":
        setExpertise(nextValues)
        break
      case "industries":
        setIndustries(nextValues)
        break
    }
  }

  // const countryValue = useMemo(() => {
  //   const key = countries.getLabelByCode(countryAlpha3)
  //   const option = countries.listAlpha3.find(({ value }) => value === key)
  //   return option || undefined
  // }, [countryAlpha3])

  const handleSubmit = async () => {
    const _expertise = expertise ? expertise.map(({ value }) => value) : []
    const _industries = industries ? industries.map(({ value }) => value) : []

    const payload: IExpertPayload = {
      altEmail,
      altPhone: altPhone ? `+${altPhone.replaceAll("+", "")}` : "",
      phone: `+${phone.replaceAll("+", "")}`,
      linkedinUrl,
      externalUrl: (externalUrl || "").trim(),
      timezone: timezone?.value || "",
      countryAlpha3: countryAlpha3 || null,
      expertise: _expertise,
      industries: _industries,
    }

    try {
      const { data } = await updateProfile({ variables: { payload } })

      if (data?.updateProfile.success) {
        alert.success("Profile updated successfully")
        await refetch()
        onClose()
        window.scrollTo(0, 0)
      } else {
        alert.error(data?.updateProfile.description)
      }
    } catch (e) {
      /*eslint-disable */
      console.error(e)
      /*eslint-enable */

      // @ts-ignore
      alert.error(e?.message || e)
    }
  }

  useEffect(() => {
    const handleOrientationChange = () => setOrientation(window?.screen?.orientation?.type)
    window.addEventListener("orientationchange", handleOrientationChange)
    return () => window.removeEventListener("orientationchange", handleOrientationChange)
  }, [])

  return (
    <>
      <StackItem>
        <Label>Contacts</Label>

        <VStack>
          <EditContactItem>
            <Box width={21} />
            <SubLabel fontWeight={4}>Country</SubLabel>
          </EditContactItem>

          <EditContactItem>
            <ContactBox zIndex={3}>
              <MIcon v={2} icon="place" />
              <LocationsSelect
                menuShouldScrollIntoView
                format="alpha3"
                valueKey={countryAlpha3}
                onChange={(item: SelectOption<string>) => {
                  setCountryAlpha3(countries.getCodeByLabel(item?.value))
                }}
              />
            </ContactBox>
          </EditContactItem>

          <EditContactItem mt={9}>
            <Box width={21} />
            <SubLabel fontWeight={4}>Timezone</SubLabel>
          </EditContactItem>

          <EditContactItem>
            <ContactBox zIndex={2}>
              <MIcon v={2} icon="schedule" />

              <TimezoneSelect
                value={timezone}
                onChange={(value: any) => {
                  // @ts-ignore
                  setTimezone(value)
                }}
              />
            </ContactBox>
          </EditContactItem>

          <EditContactItem mt={9}>
            <Box width={21} />
            <SubLabel fontWeight={4}>Phone number</SubLabel>
          </EditContactItem>
          <EditContactItem>
            <ContactBox zIndex={1}>
              <MIcon v={2} icon="phoneSharp" />
              <PhoneSelect
                mandatory
                phone={phone}
                setPhone={setPhone}
                isValid={isPhoneValid}
                setIsValid={setIsPhoneValid}
              />
            </ContactBox>
          </EditContactItem>

          <ErrorHint show={!isPhoneValid} text="Phone number is not valid" />

          {showAltPhone ? (
            <>
              <EditContactItem mt={9}>
                <Box width={21} />
                <SubLabel fontWeight={4}>Alternative phone number</SubLabel>
              </EditContactItem>
              <EditContactItem>
                <ContactBox>
                  <MIcon v={2} icon="phoneSharp" />
                  <PhoneSelect
                    phone={altPhone}
                    setPhone={setAltPhone}
                    isValid={isAltPhoneValid}
                    setIsValid={setIsAltPhoneValid}
                  />
                </ContactBox>
              </EditContactItem>

              <ErrorHint show={altPhone.length > 0 && !isAltPhoneValid} text="Phone number is not valid" />
            </>
          ) : (
            <EditContactItem>
              <Button ghost onClick={() => setShowAltPhone(true)}>
                + Add an alternative phone number
              </Button>
            </EditContactItem>
          )}

          <EditContactItem mt={9}>
            <MIcon v={2} icon="email" />
            <Text>{me?.email}</Text>
          </EditContactItem>

          {showAltEmail ? (
            <>
              <EditContactItem>
                <Box width={21} />
                <SubLabel fontWeight={4}>Email</SubLabel>
              </EditContactItem>
              <EditContactItem>
                <ContactBox>
                  <MIcon v={2} icon="email" />{" "}
                  <StyledInput
                    name="altEmail"
                    placeholder="Type here..."
                    value={altEmail}
                    onChange={e => setAltEmail(e.target.value)}
                  />
                </ContactBox>
              </EditContactItem>

              <ErrorHint show={!isAltEmailValid} text="Email is not valid" />
            </>
          ) : (
            <EditContactItem>
              <Button ghost onClick={() => setShowAltEmail(true)}>
                + Add an alternative email
              </Button>
            </EditContactItem>
          )}

          <EditContactItem mt={9}>
            <Box width={21} />
            <SubLabel fontWeight={4}>LinkedIn profile link</SubLabel>
          </EditContactItem>
          <EditContactItem>
            <ContactBox>
              <MIcon icon="linkedIn" />{" "}
              <StyledInput
                name="LinkedIn"
                placeholder="Type here..."
                invalid={!linkedInValid}
                value={linkedinUrl}
                onChange={e => setLinkedinUrl(e.target.value)}
              />
            </ContactBox>
          </EditContactItem>

          <ErrorHint show={!linkedInValid} text="Is not a valid URL" />

          <EditContactItem mt={9}>
            <Box width={21} />
            <SubLabel fontWeight={4}>Web-site URL</SubLabel>
          </EditContactItem>

          <EditContactItem>
            <ContactBox>
              <MIcon v={2} icon="link" style={{ transform: "rotate(-45deg)" }} />{" "}
              <StyledInput
                name="https://"
                placeholder="Type here..."
                invalid={!externalUrlValid}
                value={externalUrl}
                onChange={e => {
                  setExternalUrl(e.target.value)
                }}
              />
            </ContactBox>
          </EditContactItem>

          <ErrorHint show={!externalUrlValid} text="Is not a valid URL" />

          <EditContactItem mt={9}>
            <MIcon v={2} icon="payment" />
            <Text>{me?.rate ? `${getCurrencyLabel(me?.currency)} ${me?.rate} / hour` : "Hourly rate"}</Text>

            <Box ref={ref} style={{ position: "fixed", height: 0, left: 0, right: 0, top: "-100%" }} />

            <Popup
              width={popupSettings.width}
              placement={popupSettings.placement}
              onClick={e => e.preventDefault()}
              contentStyle={{
                overflowY: "auto",
                padding: "14px 12px",
              }}
              content={() => (
                <Text fontWeight={400} fontSize={5}>
                  Please, note that you are being paid on a per-minute basis.
                </Text>
              )}
            >
              <Box
                cursor="pointer"
                color={"beta.400"}
                hoverColor={"beta.600"}
                style={{
                  marginLeft: "3px",
                }}
              >
                <>
                  <MIcon v={1} icon="info" />
                </>
              </Box>
            </Popup>
          </EditContactItem>
        </VStack>
      </StackItem>

      <EditBox label="Expertise" subLabel="Your expertise">
        <StyledCreatableSelect
          isMulti
          menuPlacement="bottom"
          name="expertise"
          formatCreateLabel={(value: string) => `Add "${value}"`}
          contentEditable
          onSelectResetsInput={false}
          components={{
            Option: ReactSelectOption,
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
            Input: MultiSelectInput,
            MultiValueContainer: MultiSelectValueContainer,
          }}
          withoutForm
          oblique
          placeholder="Use ; to add several"
          options={expertisesOptions}
          value={expertise}
          onChange={onCreatableSelectChange.bind(null, "expertise")}
        />
      </EditBox>

      <EditBox label="Industry" subLabel="Industries of expertise">
        <StyledCreatableSelect
          isMulti
          name="industries"
          menuPlacement="top"
          formatCreateLabel={(value: string) => `Add "${value}"`}
          contentEditable
          onSelectResetsInput={false}
          components={{
            Option: ReactSelectOption,
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
            Input: MultiSelectInput,
            MultiValueContainer: MultiSelectValueContainer,
          }}
          withoutForm
          oblique
          placeholder="Use ; to add several"
          options={industriesOptions}
          value={industries}
          onChange={onCreatableSelectChange.bind(null, "industries")}
        />
      </EditBox>

      <StackItem>
        <ActionBox>
          <StyledButton type="button" disabled={isFormInvalid} loading={loading} onClick={handleSubmit}>
            Save changes
          </StyledButton>
          <Box m={2} />
          <StyledButton
            type="button"
            variant="secondary"
            onClick={() => {
              window.scrollTo(0, 0)
              onClose()
            }}
          >
            Discard
          </StyledButton>
        </ActionBox>
      </StackItem>
    </>
  )
}
