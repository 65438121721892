import { useHistory } from "react-router"
import { useEffect, useMemo } from "react"
import { ROUTES } from "components/constants"
import { AuthError } from "./@error-page"
import { PublicPage } from "./@public-page"
import { useLocation } from "react-use"
import { useMe } from "hooks"
import { sessionStorage } from "shared/storage"
import { LimitedAuthGuard } from "hocs/LimitedAuthGuard"

export const Auth = () => {
  const { push } = useHistory()
  const { hostname, search, pathname } = useLocation()
  const { me, loading, loggedIn } = useMe()
  const isLocal = useMemo(() => hostname === "localhost", [hostname])

  const params = useMemo(() => {
    const params = new URLSearchParams(search)

    const action = params.get("action")
    const projectId = params.get("project_external_id")
    const projectAction = params.get("project_action")
    const firstSeen = params.get("first_seen")
    const firstLogin = firstSeen === "true"

    return { projectId, projectAction, action, firstLogin }
  }, [search])

  // const { data, loading } = useMeQuery()

  const handleLogout = () => {
    window.location.href = ROUTES.authLogin
  }

  const handleIAMAuth = () => {
    if (params.firstLogin) {
      return push(`${ROUTES.askPhone}?welcome=true`)
    }

    const goTo = sessionStorage.get("goTo")

    if (!me?.state) return

    const { isPhoneConfirmed, isRecentEmploymentsConfirmed } = me!.state

    if (!isPhoneConfirmed) {
      push(ROUTES.askPhone + search)
    } else if (goTo) {
      if (pathname !== goTo) {
        // window.location.href = goTo
        push(goTo)
      } else {
        sessionStorage.remove("goTo")
      }
    } else if (!isRecentEmploymentsConfirmed) {
      push(ROUTES.mostRecentEmployment)
    } else {
      push(ROUTES.workspace)
    }
    // isPhoneConfirmed && isRecentEmploymentsConfirmed ? push(ROUTES.workspace) : push(ROUTES.askPhone + search)
  }

  const handleProjectInvite = () => {
    switch (params.projectAction) {
      case "reject":
        push(ROUTES.optOutProject + search)
        break
      case "accept":
        params.projectId ? push(ROUTES.screeningMain + search) : push(ROUTES.askPhone + search)
        break
      case "error":
        // handle IAM error
        break
    }
  }

  useEffect(() => {
    if (loading) {
      return
    }

    if ((search && params && loggedIn) || (!search && loggedIn)) {
      switch (params.action) {
        case "project_invite":
          handleProjectInvite()
          break
        case "logout":
          handleLogout()
          break
        case "error":
          break
        case "auth":
        default:
          handleIAMAuth()
      }
    }

    if (params.action === "logout" && !loggedIn && !isLocal) {
      handleLogout()
    }
  }, [search, params, me])

  return (
    <LimitedAuthGuard>
      <>
        {params.action === "error" && <AuthError />}
        {isLocal && params.action === "logout" && <PublicPage />}
      </>
    </LimitedAuthGuard>
  )
}
