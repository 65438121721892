// const basePath = process.env.REACT_APP_API_BASE || '';
// const aaa = (basePath + "/b/iam/logout/").toString();

export enum ROUTES {
  authLogout = "/b/iam/logout/",
  authLogin = "/b/iam/oauth/authorize/",
  authSignUp = "/b/iam/request-signup/",
  authChangePassword = "/b/iam/edit-password/",

  welcome = "/",
  base = "/base",
  auth = "/auth",

  // accountDeactivated = "/account-deactivated",
  devLogin = "/dev-login",

  login = "/login",
  page403 = "/403",
  page404 = "/404",
  page500 = "/500",
  // Screening
  screening = "/screening",
  inviteAccepted = "/screening/invite-accepted",
  backToPortal = "/screening/back-to-portal",
  screeningSubmitted = "/screening/submitted",
  askPhone = "/screening/ask-for-phone-number",
  linkExpired = "/screening/the-link-has-expired",
  screeningMain = "/screening/main",
  projectInvitationDeclined = "/screening/project-invitation-declined",
  optOutProject = "/screening/opt-out_of_the_project",

  profile = "/profile",
  myProfile = "/profile/me",
  mostRecentEmployment = "/profile/most_recent_employment",

  documents = "/documents",

  projects = "/projects",

  payments = "/payments",

  workspace = "/workspace",

  compliance = "/compliance",
  complianceTraining = "/compliance/training",
  complianceReminder = "/compliance/training_reminder",
  complianceComplete = "/compliance/complete",
}

export enum LOCAL_STORAGE_TEMPLATE {
  token = "JWT_AUTH_TOKEN",
  refreshToken = "refreshToken",
  congratulationModalLevel = "congratulationModalLevel",
}

export const HEADER_DESKTOP_HEIGHT = 56
export const HEADER_MOBILE_HEIGHT = 40
