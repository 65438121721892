import { Box, Flex, Image, Spinner } from "@prosapient/prosapient-styleguide"
import ImageBG from "accets/images/most-recent-employment.svg"
import { useHistory } from "react-router"
import { ROUTES } from "components/constants"

import { ContentWithBackground, DesktopFooter, FirstParagraph, MobileFooter, StyledBox, Wrapper } from "./styles"
import { IEmployment, Maybe, useEmploymentsQuery } from "api/__generated__/schema"
import { useEffect, useMemo } from "react"
import { monthsShortFormat } from "data/months"
import { Header } from "components/shared/Header"
import { RecentEmploymentType } from "./types"
import { Employments } from "./components/Employments"
import { ActionButtons } from "./components/ActionButtons"
import { useMe } from "hooks"
import { Footer } from "components/shared/Footer"

const INFO =
  "It’s very important to know your recent employment as this information is used to add you to different projects"

const generateDisplayDate = (month: Maybe<number> | undefined, year?: Maybe<number> | undefined): string => {
  const isData = typeof month === "number" || year

  if (!isData) return ""

  const endMonth = typeof month === "number" ? monthsShortFormat[month] : ""
  const endYear = year || ""
  return [endMonth, endYear].filter(Boolean).join(" ")
}

export const RecentEmployment = () => {
  const { push } = useHistory()
  const {
    me: {
      state: { isRecentEmploymentsConfirmed, isCompliancePassed },
    },
  } = useMe()

  const { data, loading } = useEmploymentsQuery({
    fetchPolicy: "cache-and-network",
    errorPolicy: "ignore",
    variables: { isRecentOnly: true },
  })

  const recentEmployments = useMemo<null | IEmployment[]>(() => {
    return data?.expert?.employments || null
  }, [data?.expert?.employments])

  const isOneEmployment = useMemo<boolean>(() => data?.expert?.employments?.length === 1, [data?.expert?.employments])

  const employments: RecentEmploymentType[] = useMemo(() => {
    if (!recentEmployments) return []

    return recentEmployments.map(recentEmployment => {
      const result = { position: "", company: "", displayDate: "" }

      const startDate = generateDisplayDate(recentEmployment?.startMonth, recentEmployment?.startYear)
      const endDate = generateDisplayDate(recentEmployment?.endMonth, recentEmployment?.endYear)

      let displayDate = ""
      if (startDate && !endDate) displayDate += "Since "
      if (!startDate && endDate) displayDate += "Until "

      displayDate += [startDate, endDate].filter(Boolean).join(" - ")

      result.position = recentEmployment?.position || "-"
      result.company = recentEmployment?.company || "-"
      result.displayDate = displayDate

      return result
    })
  }, [recentEmployments])

  useEffect(() => {
    if (isRecentEmploymentsConfirmed) {
      isCompliancePassed ? push(ROUTES.myProfile) : push(ROUTES.complianceTraining)
    }
  }, [isRecentEmploymentsConfirmed, isCompliancePassed])

  useEffect(() => {
    if (data?.expert && !recentEmployments?.length) push(`${ROUTES.myProfile}?tab=history`)
  }, [recentEmployments, loading])

  if (loading || !recentEmployments?.length) {
    return (
      <>
        <Header />

        <Wrapper style={{ flexDirection: "column" }}>
          <Box m="auto">
            <Spinner sizing="lg" />
          </Box>
        </Wrapper>
      </>
    )
  }

  return (
    <>
      <Header />

      <Wrapper style={{ flexDirection: "column" }}>
        <ContentWithBackground m="auto auto 0">
          <StyledBox maxWidth="calc(530px - 4rem)">
            <Flex flexDirection="column" style={{ textAlign: "center" }} width="100%">
              <Image
                src={ImageBG}
                alt="Invitation declined image"
                style={{ width: "416px", height: "226px", maxWidth: "100%" }}
              />

              <FirstParagraph mt={isOneEmployment ? "3rem" : "1rem"} mb={isOneEmployment ? 10 : 8}>
                {isOneEmployment ? "Is this your most recent employment?" : "Are these your most recent employments?"}
              </FirstParagraph>

              <Employments employments={employments} />

              <MobileFooter>{INFO}</MobileFooter>
            </Flex>

            <ActionButtons oneEmployment={isOneEmployment} />
          </StyledBox>
        </ContentWithBackground>

        <DesktopFooter>{INFO}</DesktopFooter>

        <Footer />
      </Wrapper>
    </>
  )
}
