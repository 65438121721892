import { FormValue, SelectOption, SerializedFormValue } from "shared/form/types"
import { format } from "date-fns/esm"
import * as R from "ramda"

const isSimpleSelect = (v: FormValue): v is SelectOption<string> => {
  if (
    v &&
    typeof v !== "boolean" &&
    typeof v !== "string" &&
    typeof v !== "number" &&
    !(v instanceof Date) &&
    !Array.isArray(v) &&
    v.label &&
    v.value
  ) {
    return true
  }

  return false
}

const isImageUploading = (v: FormValue): v is SelectOption<string> => {
  return !!(
    v &&
    typeof v !== "boolean" &&
    typeof v !== "string" &&
    typeof v !== "number" &&
    !(v instanceof Date) &&
    !Array.isArray(v) &&
    v.url &&
    v.value
  )
}

const isMultiSelect = (v: FormValue): v is SelectOption<string>[] => {
  if (Array.isArray(v) && v.length > 0) {
    const [firstValue] = v

    if (typeof firstValue !== "string" && !R.isNil(firstValue) && firstValue.label && firstValue.value) {
      return true
    }
  }

  return false
}

const serializeValue = (v: FormValue): SerializedFormValue => {
  if (R.isNil(v)) {
    return null
  }

  if (isSimpleSelect(v)) {
    return v.value
  }

  if (isMultiSelect(v)) {
    return v.map((value: SelectOption<string>) => value.value)
  }

  if (typeof v === "string" || typeof v === "boolean" || typeof v === "number") {
    return v
  }

  if (v instanceof Date) {
    return format(v, "yyyy-MM-dd")
  }

  if (Array.isArray(v)) {
    return v.filter(v => !R.isNil(v))
  }

  if (isImageUploading(v)) {
    return v.value
  }

  return Object.entries(v).reduce((acc: { [s: string]: SerializedFormValue }, kv) => {
    const [key, value] = kv

    acc[key] = serializeValue(value)
    return acc
  }, {})
}

export const parseFloatForm = (value: string) => (isNaN(parseFloat(value)) ? undefined : parseFloat(value))
export const parseIntForm = (value: string) => (isNaN(parseInt(value)) ? undefined : parseInt(value))
export const parseIntInSelectForm = (value: SelectOption<string>) => {
  const coercedValue = isNaN(parseInt(value.value)) ? 0 : parseInt(value.value)
  return {
    value: coercedValue,
    label: value.label,
  }
}

export const serializeValues = (
  values: { [s: string]: FormValue },
  permittedKeys?: string[]
): { [s: string]: SerializedFormValue } =>
  Object.entries(values).reduce((acc: { [s: string]: SerializedFormValue }, kv) => {
    const [key, value] = kv

    if (permittedKeys === undefined || permittedKeys.includes(key)) {
      acc[key] = serializeValue(value)
    }

    return acc
  }, {})
