import { Box, Button, Image, Link, Text } from "@prosapient/prosapient-styleguide"
import ImageBG from "accets/images/error_occurred.svg"
import { useHistory, useLocation } from "react-router"
import { ROUTES } from "components/constants"
import { Title } from "components/shared/Layout"
import { PageContainer } from "components/shared/Layout/PageContainer"
import styled from "styled-components"
import { Helmet } from "react-helmet"
import { decode } from "js-base64"
import { useEffect, useMemo } from "react"

const StyledBox = styled(Box).attrs({ p: 10, maxWidth: 640, width: "100vw" })`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  margin: auto;

  @media only screen and (max-width: 640px) {
    max-width: 100%;
    margin: 0 auto;
    align-self: flex-start;
    box-shadow: none;
    padding: 1rem;
    min-height: calc(var(--vh));
  }
`

const StyledButton = styled(Button)`
  @media only screen and (max-width: 640px) {
    width: 100%;
    margin-top: auto;
    padding-top: 1px;
    padding-bottom: 1px;
    min-height: 40px;
  }
`

const Wrapper = styled(PageContainer).attrs({ hasBackground: false })`
  min-height: var(--vh) !important;
`

const Description = styled(Text).attrs({
  mt: 1,
  mb: 9,
  color: "beta.800",
  fontWeight: 1,
  fontSize: 7,
  lineHeight: 1.75,
})``

const Support = styled(Link).attrs({
  fontWeight: 2,
})`
  a {
    color: ${({ theme }) => theme.colors.alpha[600]} !important;
    text-decoration: none;

    &:hover {
      color: ${({ theme }) => theme.colors.alpha[800]} !important;
      text-decoration: underline;
    }
  }
`

export const AuthError = () => {
  const { push } = useHistory()
  const { search } = useLocation()

  const details = useMemo(() => {
    const params = new URLSearchParams(search)
    const details = params.get("details") || ""

    let result = details
    try {
      result = decode(details)
      if (result === "User not found. Please try again later") result = "User not found. "
    } catch (e) {
      // @ts-ignore
      new Error(e)
    }

    return result
  }, [search])

  useEffect(() => {
    if (details === "Link has expired") push(ROUTES.linkExpired)
    // if (details === "User was deactivated") push(ROUTES.accountDeactivated)
  }, [details])

  const handleLogout = () => {
    // @ts-ignore
    window.location = ROUTES.authLogout
  }

  // return (
  //   <Wrapper>
  //     <Helmet>
  //       <Title>Error occurred</Title>
  //       <link rel="preload" as="image" href={ImageBG} />
  //     </Helmet>
  //
  //     <StyledBox>
  //       <Box width="100%" mb={7}>
  //         <Title>Error occurred</Title>
  //
  //         <StyledParagraph>{details}</StyledParagraph>
  //       </Box>
  //
  //       <StyledButton onClick={handleLogout}>Log Out</StyledButton>
  //     </StyledBox>
  //   </Wrapper>
  // )

  return (
    <Wrapper>
      <Helmet>
        <link rel="preload" as="image" href={ImageBG} />
      </Helmet>

      <StyledBox>
        <Box width="100%">
          <Image
            src={ImageBG}
            alt="Invitation declined image"
            style={{ maxWidth: "100%", width: "621px", height: "337px" }}
          />

          <Title mt={10}>Error occurred</Title>

          <Description>
            {details} For more information, please, contact{" "}
            <Support to="mailto:support@lek.com">support@lek.com</Support>
          </Description>
        </Box>

        <StyledButton onClick={handleLogout}>Log Out</StyledButton>
      </StyledBox>
    </Wrapper>
  )
}
