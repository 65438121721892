/*
 * Icons library
 * style: Rounded
 * https://fonts.google.com/icons?selected=Material+Icons&icon.style=Rounded
 *
 * */

/*
 *
 * Import icons using one of these two options:
 * https://mui.com/components/icons/#usage
 *
 * Option 1:
 * import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
 * import ThreeDRotation from '@mui/icons-material/ThreeDRotation';
 *
 * Option 2:
 * import { AccessAlarm, ThreeDRotation } from '@mui/icons-material';
 *
 * The safest for bundle size is Option 1, but some developers prefer Option 2.
 * Make sure you follow the minimizing bundle size guide before using the second approach.
 *
 * */

import InfoRounded from "@mui/icons-material/InfoRounded"
import PhoneRounded from "@mui/icons-material/PhoneRounded"
import PhoneSharp from "@mui/icons-material/PhoneSharp"
import DownloadFilled from "@mui/icons-material/Download"
import CloseFilled from "@mui/icons-material/Close"
import PlaceRounded from "@mui/icons-material/PlaceRounded"
import EmailRounded from "@mui/icons-material/EmailSharp"
import EditSharp from "@mui/icons-material/EditSharp"
import ErrorRounded from "@mui/icons-material/ErrorRounded"
import CheckCircleRounded from "@mui/icons-material/CheckCircleRounded"
import LinkRounded from "@mui/icons-material/LinkRounded"
import PaymentRounded from "@mui/icons-material/PaymentRounded"
import ScheduleRounded from "@mui/icons-material/ScheduleRounded"
import WorkOutlineRounded from "@mui/icons-material/WorkOutlineRounded"

import { MIcons } from "./types"

export const GOOGLE_ICONS: MIcons = {
  info: InfoRounded,
  phone: PhoneRounded,
  phoneSharp: PhoneSharp,
  download: DownloadFilled,
  close: CloseFilled,
  place: PlaceRounded,
  email: EmailRounded,
  edit: EditSharp,
  error: ErrorRounded,
  check_circle: CheckCircleRounded,
  link: LinkRounded,
  payment: PaymentRounded,
  schedule: ScheduleRounded,
  work: WorkOutlineRounded,
}
