import { useEffect, useMemo, useState } from "react"
import styled from "styled-components"
import { Box } from "@prosapient/prosapient-styleguide"
import { NavTab, NavTabs } from "components/shared/Layout"
import { Card } from "components/shared/Card"
import { EmptyList } from "./EmptyList"
import { IProjectCategory, useExpertEventsSubscription, useProjectsLazyQuery } from "api/__generated__/schema"

// the tab is hidden according to EP-622
// type SectionTab = "actions_needed" | "waiting" | "follow_up"
type SectionTab = "actions_needed" | "waiting"

const DesktopNavTabs = styled(NavTabs)`
  display: flex;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: #d4d2da;
  justify-content: flex-start;
  margin-bottom: 1.5rem;

  @media only screen and (max-width: 1024px) {
    margin-bottom: auto;
  }

  ${NavTab} {
    flex: none;
    margin-bottom: -1px;
    margin-left: 1.75rem;
    margin-right: 1.75rem;

    @media only screen and (max-width: 640px) {
      flex: 1;
      margin-left: 1.69rem;
      margin-right: 1.69rem;
    }
  }
`

export const ProjectList = () => {
  const [tab, setTab] = useState<SectionTab>("actions_needed")

  const { data: expertEventsData } = useExpertEventsSubscription()

  const [loadProjects, { data, loading }] = useProjectsLazyQuery({
    fetchPolicy: "cache-and-network",
    variables: { category: IProjectCategory.ActionNeeded },
  })

  const tabsMap = {
    actions_needed: {
      title: "Actions needed",
      refetch: loadProjects.bind(null, {
        fetchPolicy: "cache-and-network",
        variables: { category: IProjectCategory.ActionNeeded },
      }),
    },
    waiting: {
      title: "Waiting",
      refetch: loadProjects.bind(null, {
        fetchPolicy: "cache-and-network",
        variables: { category: IProjectCategory.Waiting },
      }),
    },
    // the tab is hidden according to EP-622
    // follow_up: {
    //   title: "Follow up",
    //   refetch: loadProjects.bind(null, {
    //     fetchPolicy: "cache-and-network",
    //     variables: { category: IProjectCategory.FollowUp },
    //   }),
    // },
  }

  const tabs = useMemo(() => {
    return Object.entries(tabsMap).map(([key, values]) => ({ key, ...values }))
  }, [tabsMap])

  useEffect(() => {
    if (tab) tabsMap[tab].refetch()
  }, [expertEventsData])

  useEffect(() => {
    tabsMap[tab].refetch()
  }, [])

  return (
    <Box width="100%">
      <DesktopNavTabs>
        {tabs.map(({ key, title, refetch }) => (
          <NavTab
            key={key}
            color={tab === key ? "alpha.600" : "beta.800"}
            hoverColor={tab === key ? "alpha.700" : "beta.700"}
            borderColor={tab === key ? "alpha.700" : "beta.300"}
            onClick={() => {
              refetch()
              setTab(key as SectionTab)
            }}
          >
            {title}
          </NavTab>
        ))}
      </DesktopNavTabs>

      {!loading &&
        data?.projects?.map(({ externalId, name, status, invitationBrief, actions, screeningQuestions }) => (
          <Card
            key={externalId}
            externalId={externalId}
            title={name}
            status={status}
            description={invitationBrief}
            actions={actions}
            hasScreeningQuestions={screeningQuestions.length > 0}
          />
        ))}

      {!loading && !data?.projects?.length && (
        <EmptyList>
          {tab === "actions_needed" &&
            "Here you will find all information about the projects that require actions from you."}
          {tab === "waiting" && "Here you will find all information about the projects that are in the waiting state."}
          {/*{tab === "follow_up" && "Here you will find all information about the projects that require a follow up."}*/}
        </EmptyList>
      )}
    </Box>
  )
}
