import { Alert, Button, Flex, useAlert } from "@prosapient/prosapient-styleguide"
import { ROUTES } from "components/constants"
import { useConfirmRecentEmploymentMutation } from "api/__generated__/schema"
import { useHistory } from "react-router"
import styled from "styled-components"
import { useMe } from "hooks"

const StyledButton = styled(Button)`
  @media only screen and (max-width: 640px) {
    width: 100%;
    margin-top: auto;
    padding-top: 1px;
    padding-bottom: 1px;
    min-height: 40px;
  }
`

const Wrapper = styled(Flex).attrs({ mt: 9, mx: "auto" })`
  background: white;
  grid-gap: 4px;
  position: sticky;
  bottom: 0;
  box-shadow: 0 126px 0 130px white;

  @media only screen and (max-width: 640px) {
    width: 100%;
    flex-wrap: wrap;
    margin-top: auto;
    bottom: 1rem;
  }
`

type IProps = { oneEmployment: boolean }

export const ActionButtons = ({ oneEmployment }: IProps) => {
  const { push } = useHistory()
  const {
    me: {
      state: { isCompliancePassed },
    },
  } = useMe()
  const alert = useAlert(Alert.Context)

  const [confirmRecentEmployment, { loading }] = useConfirmRecentEmploymentMutation()

  const confirmRecentEmploymentHandler = async () => {
    try {
      const { data } = await confirmRecentEmployment()
      const success = data?.confirmRecentEmployment?.success

      if (success) {
        isCompliancePassed ? push(ROUTES.myProfile) : push(ROUTES.complianceTraining)
      } else {
        alert.error(data?.confirmRecentEmployment.description)
      }
    } catch (e) {
      // eslint-disable-next-line
      console.error(e)

      // @ts-ignore
      alert.error(e?.message || e)
    }
  }

  return (
    <Wrapper>
      <StyledButton disabled={loading} loading={loading} type="submit" onClick={confirmRecentEmploymentHandler}>
        {oneEmployment ? "Yes, it’s my most recent employment." : "Yes, that’s correct"}
      </StyledButton>

      <StyledButton
        disabled={loading}
        type="button"
        variant="secondary"
        onClick={() => push(`${ROUTES.myProfile}?tab=history`)}
      >
        No, it’s not
      </StyledButton>
    </Wrapper>
  )
}
