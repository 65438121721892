import { GraphQLWsLink } from "@apollo/client/link/subscriptions"
import { ApolloClient, InMemoryCache } from "@apollo/client"
import { LOCAL_STORAGE_TEMPLATE, ROUTES } from "components/constants"
import { createClient } from "graphql-ws"
import { createUploadLink } from "apollo-upload-client"

const apiParams = JSON.parse(process.env.REACT_APP_API_CONFIG || "{}")

function getCookie(key: string) {
  // eslint-disable-next-line
  const b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)")
  return b ? b.pop() : ""
}

// const SERVER_URL = config!.api!.uri || ""
const cache = new InMemoryCache({})

const createApolloClient = () => {
  const token = getCookie(LOCAL_STORAGE_TEMPLATE.token)
  const isLocal = window.location.hostname === "localhost"

  const link = new GraphQLWsLink(
    createClient({
      // url: SERVER_URL,
      // url: "wss://expert-portal.test.prosapient.app/graphql/",
      url: `wss://${isLocal ? "expert-portal.test.prosapient.app" : window.location.hostname}/graphql/`,
      connectionParams: {
        authorization: token ? `JWT ${token}` : "",
        ...apiParams,
      },
      lazyCloseTimeout: 10000,
      on: {
        message: message => {
          if (message?.type === "error" && Array.isArray(message?.payload)) {
            const isLocal = window.location.hostname === "localhost"

            const expertNeedsToLogin = message.payload.some((str: any) =>
              ["Expert is not active", "Expert IAM session is not valid"].includes(str?.message)
            )
            if (expertNeedsToLogin) {
              if (!isLocal) window.location.href = ROUTES.authLogin
            }
          }
        },
      },
    })
  )

  return new ApolloClient({ link, cache })
}

const webSocketClient = createApolloClient()

const createHttpApolloClient = () => {
  const token = getCookie(LOCAL_STORAGE_TEMPLATE.token)
  const isLocal = window.location.hostname === "localhost"

  const link = createUploadLink({
    // uri: "https://expert-portal.test.prosapient.app/graphql/",
    uri: `https://${isLocal ? "expert-portal.test.prosapient.app" : window.location.hostname}/graphql/`,
    headers: {
      authorization: token ? `JWT ${token}` : "",
    },
    credentials: "same-origin",
  })

  // @ts-ignore
  return new ApolloClient({ link, cache })
}

const httpClient = createHttpApolloClient()

export { webSocketClient, httpClient }
